import CircleIcon from '@/components/atoms/Icon/svg/CircleIcon'
import DashIcon from '@/components/atoms/Icon/svg/DashIcon'
import { PLAN_ID } from '@/constant/options'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useMemo } from 'react'

const useStyle = makeStyles({
    container: {
        width: 700,
        maxWidth: 700,
    },
    cellSubHeader: {
        color: theme.colors.white,
        backgroundColor: theme.colors.primaryN,
        fontSize: 14,
        fontWeight: 600,
        border: `3px solid ${theme.colors.primaryN}`,
    },
    cell: {
        fontSize: 14,
        fontWeight: 300,
    },
    firstColumn: {
        fontWeight: 600,
        backgroundColor: '#D8E3EA',
    },
    firstColumnBorder: {
        position: 'relative',
        '&:after': {
            position: 'absolute',
            inset: 0,
            content: '""',
            width: '100%',
            borderBottom: '3px solid #fff',
        },
    },
    header: {
        borderTop: `1px solid ${theme.colors.border}`,
    },
    itemCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    trialText: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 30,
    },
    planActive: {
        borderLeft: `3px solid ${theme.colors.primaryN}`,
        borderRight: `3px solid ${theme.colors.primaryN}`,
    },
    planActiveLastCell: {
        borderBottom: `3px solid ${theme.colors.primaryN}`,
    },
})
type SubHeaders = {
    id: number
    name: string
    width: number
    isActive: boolean
}

export default function PlanTable() {
    const classes = useStyle()
    const { storeState } = useStore()
    const currentPlan = useMemo(() => {
        const plan = {
            isStarter: false,
            isBasic: false,
            isEnterprise: false,
            isTrial: false,
        }
        if (!storeState.organization?.planId) return plan
        if (storeState.organization.planId === PLAN_ID.starter) {
            plan.isStarter = true
        }
        if (storeState.organization.planId === PLAN_ID.basic) {
            plan.isBasic = true
        }
        if (storeState.organization.planId === PLAN_ID.enterprise) {
            plan.isEnterprise = true
        }
        if (storeState.organization.planId === PLAN_ID.trial) {
            plan.isTrial = true
        }
        return plan
    }, [storeState.organization?.planId])

    const checkIndexSubheader = (data: SubHeaders[]) => {
        const index = data.findIndex((d) => d.isActive)
        return index
    }
    const subHeaders: SubHeaders[] = useMemo(() => {
        return [
            { id: 1, name: '', width: 192, isActive: false },
            { id: 2, name: 'ご契約中', width: 163, isActive: currentPlan.isStarter },
            {
                id: 3,
                name: currentPlan.isTrial ? '適用中' : 'ご契約中',
                width: 163,
                isActive: currentPlan.isBasic || currentPlan.isTrial,
            },
            {
                id: 4,
                name: 'ご契約中',
                width: 163,
                isActive: currentPlan.isEnterprise,
            },
        ]
    }, [currentPlan])

    const headers = [
        { id: 1, name: 'プラン名', width: 192 },
        { id: 2, name: 'スターター', width: 163 },
        { id: 3, name: 'ベーシック', width: 163 },
        { id: 4, name: 'エンタープライズ', width: 163 },
    ]
    const data = [
        { id: 1, name: '初期費用', starter: '5〜20万円/企業', basic: '10〜90万円/企業', enterprise: 'お見積り' },
        { id: 2, name: '月額費用', starter: '5,000円/拠点', basic: '20,000円/拠点', enterprise: 'お見積り' },
        { id: 3, name: 'Scope1-2', starter: <CircleIcon />, basic: <CircleIcon />, enterprise: <CircleIcon /> },
        { id: 4, name: 'Scope3', starter: <DashIcon />, basic: <CircleIcon />, enterprise: <CircleIcon /> },
        {
            id: 5,
            name: '削減施策レコメンド',
            starter: <CircleIcon />,
            basic: <CircleIcon />,
            enterprise: <CircleIcon />,
        },
        { id: 6, name: '分析機能', starter: <CircleIcon />, basic: <CircleIcon />, enterprise: <CircleIcon /> },
        {
            id: 8,
            name: 'サプライヤー連携機能',
            starter: <DashIcon />,
            basic: (
                <div className={classes.itemCenter}>
                    <DashIcon />
                    *1
                </div>
            ),
            enterprise: <CircleIcon />,
        },
    ]

    return (
        <div>
            {currentPlan.isTrial ? <div className={classes.trialText}>※トライアル期間中</div> : null}
            <TableContainer className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {subHeaders.map((h) => (
                                <TableCell
                                    width={h.width}
                                    key={h.id}
                                    className={h.isActive ? classes.cellSubHeader : ''}
                                    align={[1].includes(h.id) ? 'left' : 'center'}
                                >
                                    {h.isActive ? h.name : ''}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            {headers.map((h, i) => {
                                const planActive = checkIndexSubheader(subHeaders) === i
                                return (
                                    <TableCell
                                        width={h.width}
                                        key={h.id}
                                        className={`${classes.cell} ${i ? classes.header : classes.firstColumn} ${
                                            classes.firstColumnBorder
                                        } ${planActive ? classes.planActive : ''}`}
                                        align={[1].includes(h.id) ? 'left' : 'center'}
                                    >
                                        {h.name}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((d, i) => {
                            const isLastRow = i === data.length - 1
                            return (
                                <TableRow key={d.id}>
                                    <TableCell
                                        className={`${classes.cell} ${classes.firstColumn} ${
                                            !isLastRow ? classes.firstColumnBorder : ''
                                        }`}
                                    >
                                        {d.name}
                                    </TableCell>
                                    <TableCell
                                        className={`${classes.cell} ${
                                            currentPlan.isStarter ? classes.planActive : ''
                                        } ${isLastRow && currentPlan.isStarter ? classes.planActiveLastCell : ''}`}
                                        align="center"
                                    >
                                        {d.starter}
                                    </TableCell>
                                    <TableCell
                                        className={`${classes.cell} ${
                                            currentPlan.isBasic || currentPlan.isTrial ? classes.planActive : ''
                                        } ${
                                            isLastRow && (currentPlan.isBasic || currentPlan.isTrial)
                                                ? classes.planActiveLastCell
                                                : ''
                                        }`}
                                        align="center"
                                    >
                                        {d.basic}
                                    </TableCell>
                                    <TableCell
                                        className={`${classes.cell} ${
                                            currentPlan.isEnterprise ? classes.planActive : ''
                                        }  ${isLastRow && currentPlan.isEnterprise ? classes.planActiveLastCell : ''}`}
                                        align="center"
                                    >
                                        {d.enterprise}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
